/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, ContactForm, Subtitle, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" name={"ig5jf0mc36q"} layout={"l5"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style4 fs--36" content={"Kontaktujte nás pro odvoz odpadu"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --shape4 --shadow4 el--2 flex--stretch" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image className="--center" style={{"maxWidth":660}} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/40371/de253d0c99464804b772bc48c5a185ef_s=350x_.jpg 350w, https://cdn.swbpg.com/t/40371/de253d0c99464804b772bc48c5a185ef_s=660x_.jpg 660w, https://cdn.swbpg.com/t/40371/de253d0c99464804b772bc48c5a185ef_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/40371/de253d0c99464804b772bc48c5a185ef_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1 fs--14 pb--08" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno","id":"4yg8zkhzkm6n"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail","id":"fh71z87lrr4"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420","id":"7jd26p3sbr0d"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy","id":"o2pwndebugzm"},{"name":"Odeslat","type":"submit","id":"3sfy6wkeutvk"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--02 pt--16" name={"uvod-2"} style={{"backgroundColor":"rgba(235,45,45,1)"}}>
          
          <ColumnWrap className="column__flex --center el--3 pl--0 pr--0 flex--center" style={{"maxWidth":1280}} columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":311}}>
              
              <Title className="title-box fs--24" style={{"maxWidth":254}} content={"<span style=\"color: var(--color-custom-2);\">Volejte nonstop 24/7<br><br>603 512 363&nbsp;<br><br>732 527 555</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":311}}>
              
              <Title className="title-box fs--24" style={{"maxWidth":269}} content={"<span style=\"color: var(--color-custom-2);\">Likvidace stavební suti<br><br>&nbsp;a směsného odpadu</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":311}}>
              
              <Title className="title-box fs--24" content={"<span style=\"color: var(--color-custom-2);\">Kontejnery&nbsp;<br><br>3 - 15m3<br></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":311}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--02" name={"paticka"} style={{"backgroundColor":"var(--color-blend--15)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--2" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box pt--12" content={"<span style=\"color: var(--color-dominant);\">O nás</span>"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Zabýváme se odvozem a likvidací odpadu, jako je zemina, stavební suť,&nbsp; objemný odpad, bioodpad, dřevo, směsný odpad aj.<br>Služby jsou určeny jak pro&nbsp; stavební firmy, tak pro soukromé osoby.<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box pt--12" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">tel. +420 603 512 363<br>e-mail: marianbombon@seznam.cz<br><br>Autodoprava Marian Bomboň<br>IČO: 66173060<br>Cihelní 1191/95<br>Ostrava 702 00</span><span style=\"color: var(--color-dominant);\"><br></span>"}>
              </Text>

              <Text className="text-box" content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}